/* src/components/MouseEffect.css */
.mouse__cursor {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    z-index: 9999;
    border-radius: 50%;
    background-color: rgba(11, 63, 97, 0.58);
    user-select: none;
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;
}

.mouse__cursor2 {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    z-index: 9999;
    border-radius: 50%;
    background-color: rgba(11, 77, 97, 0.22);
    user-select: none;
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;
}

.mouse__cursor.active {
    transform: scale(0);
    opacity: 0.7;
}

.mouse__cursor2.active {
    transform: scale(5);
    background-color: aquamarine;
    opacity: 0.7;
}

.mouse__cursor.active2 {
    background-color: #00ffff80;
    border-color: rgb(179, 255, 0);
    transform: scale(7);
    border-radius: 10px;
    opacity: 0.7;
}

.mouse__cursor2.active2 {
    background-color: #7fffd4a2;
    border-color: rgb(255, 21, 0);
    transform: scale(1);
    border-radius: 10px;
    opacity: 0.7;
}

.mouse__cursor.active3 {
    background-color: rgba(255, 30, 0, 0.4);
    border-color: rgb(255, 21, 0);
    transform: scale(5) skew(140deg);
    opacity: 0.7;
}

.mouse__cursor2.active3 {
    background-color: rgba(255, 30, 0, 0.4);
    border-color: rgb(255, 21, 0);
    transform: scale(10) rotateX(720deg);
    opacity: 0.7;
}
