@tailwind base;
@tailwind components;
@tailwind utilities;

.montserrat{
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}


html, body, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
}

.Home{
    max-height: 100vh;
    overflow: hidden; /* 전체 페이지에서 스크롤 바를 숨김 */
}

.GeneralPage {
    min-height: 100vh;
    overflow-y: auto; /* 일반 페이지에서 스크롤 가능 */
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section {
    height: 100vh;
    width: 100%;
    position: relative;
}

.video-container {
    z-index: 1;
}

.fixed {
    position: fixed;
}

.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.bg-black {
    background-color: #000;
}

.bg-opacity-75 {
    background-color: rgba(0, 0, 0, 0.75);
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.max-w-4xl {
    max-width: 56rem;
}

.aspect-w-16 {
    aspect-ratio: 16 / 9;
}

.aspect-h-9 {
    aspect-ratio: 16 / 9;
}

.bg-white {
    background-color: #fff;
}

.p-4 {
    padding: 1rem;
}

.z-50 {
    z-index: 50;
}

.text-black {
    color: #000;
}

.text-2xl {
    font-size: 1.5rem;
}

.m-4 {
    margin: 1rem;
}

/* Tailwind CSS는 기본적으로 포함되어 있으므로 추가적인 CSS를 추가합니다. */
.dot-navigation div {
    transition: background-color 0.3s ease;
}

/* 모바일 및 태블릿에서 dot-navigation 숨기기 */
@media (max-width: 767px) {
    .dot-navigation {
        display: none;
    }
}

/* About */
@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.animate-fade-in-down {
    animation: fadeInDown 1s ease-out;
}

.animate-slide-in-left {
    animation: slideInLeft 1s ease-out;
}

.animate-slide-in-right {
    animation: slideInRight 1s ease-out;
}



/* Modal animation */
.modal-enter {
    opacity: 0;
}
.modal-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.modal-exit {
    opacity: 1;
}
.modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

/* Layout - mouse effect */
.containerLy {
    position: relative;
}

.pointerLy {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #0B3F61;
    border-radius: 50%;
    pointer-events: none;
}

/* Hide elements by default */
.hidden {
    display: none;
}

/* HOME 기본적으로 비디오를 숨기고, 로고 이미지를 표시합니다 */
.video-element {
    display: none;
}

.logo-element {
    display: block;
}

/* 화면이 768px 이상일 때 비디오를 표시하고, 로고 이미지를 숨깁니다 */
@media (min-width: 768px) {
    .video-element {
        display: block;
    }

    .logo-element {
        display: none;
    }
}

/* Home 로고 fain-in */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 0.7s ease-in-out;
}

/* src/index.css 또는 해당 CSS 파일에 추가 */
.Navigation-Container {
    position: fixed;
    right: 20px; /* 네비게이션의 위치를 조정할 수 있습니다 */
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
}

.Navigation-Anchor {
    display: block;
    width: 8px !important;
    height: 8px !important;
    margin: 15px 0 !important;
    background-color: #ffffff !important; /* 기본 dot 색상 */
    color: #ffffff !important;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.Navigation-Anchor.active {
    background-color: #ffffff !important;
    color: #ffffff !important
}

