@font-face {
    font-family: 'Pretendard Black';
    font-weight: 900;
    font-display: swap;
    src: local('Pretendard Black'), url('Pretendard-Black.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard ExtraBold';
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard ExtraBold'),
    url('Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}